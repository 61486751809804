.wpcf7-form-control {
  border: none;
  border-bottom: 1px solid rgba(39, 51, 64, .3);
  padding: 10px 0;
  width: 100%;
  outline: 0;
  font-size: 18px;
  &:active, &:focus {
    border-bottom-color: $green;
  }
}

.wpcf7-textarea {
  max-height: 200px;
}

.wpcf7-submit {
  background: linear-gradient(360deg, #43E97B 2.46%, #38F9D7 97.54%);
  color: $black;
  font-weight: 800;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 8px 35px;
  font-size: 1.1rem;
  line-height: 2;
  border-radius: 35px;
  display: table;
  margin: auto;
  width: auto;
  min-width: 180px;
}