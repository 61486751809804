.site-footer {
  section.contact {
    padding: 80px 0 30px;
  }
  .footer-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
    ul.social-accounts {
      @include list-unstyled;
      margin: 0;
      display: flex;
      align-items: center;
      li {
        margin-left: 20px;
      }
    }
  }
  @media screen and (max-width: 667px) {
    section.contact {
      padding: 30px 0;
    }
    .footer-bottom {
      flex-direction: column;
      ul.social-accounts {
        margin-top: 30px;
        li {
          margin: 0 10px;
        }
      }
    }
  }
}