.post-detail-page {
  background-color: #F9FAFC;
  .page-title-bar {
    .title-wrapper {
      background-color: transparent;
    }
  }
  .content-wrapper {
    padding-top: 30px;
    img {
      width: 100%;
      height: auto;
      box-shadow: 0px 26px 45px rgba(0, 0, 0, 0.1);
      border: none !important;
      &.aligncenter {
        display: block;
        margin: auto;
      }
    }
    pre {
      code {
        padding-left: 30px;
        padding-bottom: 30px;
        border-radius: 10px;
      }
    }
    .project-detail-content {
      margin: 30px 0;
    }
    .tags-list {
      text-align: center;
      a {
        background-color: rgba(39, 51, 64, .5);
        border-radius: 15px;
        padding: 5px 15px;
        color: #fff;
        margin: 0 5px;
        &:hover {
          background-color: $green;
        }
      }
    }
  }
  @media screen and (max-width: 667px) {
    .project-details {
      flex-direction: column;
      padding: 20px;
      margin-bottom: 10px;
      .details {
        flex-direction: column;
        display: none;
        .single-detail {
          width: 100%;
          margin-right: 0;
          text-align: center;
        }
      }
      .btn-success {
        width: 100%;
      }
    }
    .related-works {
      margin: 0 -10px;
    }
  }
}