.work-box {
  display: inline-block;
  width: 100%;
  color: $black;
  margin-bottom: 44px;
  transition: all ease .3s;
  figure {
    margin-bottom: 20px;
    overflow: hidden;
    max-height: 288px;
    img {
      width: 100%;
      height: auto;
      transition: all ease .3s;
    }
  }
  h4 {
    margin-bottom: 8px;
  }
  p {
    line-height: 1,2;
  }
  &:hover {
    figure {
      img {
        transform: rotate(-3deg) scale(1.1);
      }
    }
  }

  @media screen and (max-width: 667px) {
    margin-bottom: 30px;
  }
}