b, strong {
  font-weight: 800;
}

.section-title {
  font-size: 52px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 50px;
  @media screen and (max-width: 667px) {
    font-size: 36px;
    margin-bottom: 20px;
  }
}

.btn {
  transition: all ease .3s;
  &.btn-success {
    background: linear-gradient(360deg, #43E97B 2.46%, #38F9D7 97.54%);
  }
  &:hover {
    box-shadow: 0px 26px 45px rgba(0, 0, 0, 0.05);
    transform: translateY(-3px);
  }
}