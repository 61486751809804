.home-page-wrapper {
  section.about {
    padding: 80px 0;
    a {
      color: $black;
    }
  }
  section.skills {
    padding: 80px 0;
    background-color: #F9FAFC;
    background-size: 345px;
  }
  section.works {
    padding: 80px 0;
  }
  section.posts {
    padding: 80px 0;
    background-color: #F9FAFC;
  }

  @media screen and (max-width: 667px) {
    section.about, section.skills, section.works, section.posts {
      padding: 30px 0;
    }
  }
}