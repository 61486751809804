.page-title-bar {
  background: linear-gradient(90deg, #43E97B 2.48%, #38F9D7 97.56%);
  min-height: 430px;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url("images/slide1.svg") no-repeat center center;
    background-size: cover;
    z-index: 2;
  }
  .title-wrapper {
    background-color: #fff;
    padding: 50px 50px 0 50px;
    text-align: center;
    position: relative;
    z-index: 3;
    h1 {
      margin-bottom: 30px;
      font-size: 52px;
    }
    p {
      margin-bottom: 0;
      padding-bottom: 50px;
      border-bottom: 1px solid rgba(39, 51, 64, .2);
    }
  }
  @media screen and (max-width: 667px) {
    min-height: 300px;
    .title-wrapper {
      background: transparent;
      padding: 20px 5px;
      h1 {
        font-size: 36px;
        margin-bottom: 10px;
      }
      p {
        font-size: 18px;
        line-height: 1.2;
        margin-bottom: 0;
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }
}