.skill-box {
  padding: 36px;
  display: inline-block;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 26px 45px rgba(0, 0, 0, 0.05);
  color: $black;
  border: 5px solid #fff;
  transition: all ease .3s;
  height: 100%;
  img {
    margin-bottom: 56px;
  }
  h4 {
    font-size: 24px;
    margin-bottom: 16px;
  }
  p {
    line-height: 1.4;
    margin-bottom: 0;
  }
  &:hover {
    border-color: $green;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
}