.not-found-page {
  text-align: center;
  padding: 200px 0 80px 0;
  border-bottom: 1px solid rgba(0,0,0,.1);
  h1 {
    font-size: 42px;
    margin-bottom: 30px;
  }
  img {
    margin-bottom: 30px;
    max-width: 100%;
  }
}