$blue: #2C5DC4;
$darkblue: #000060;
$red: #EF3A4C;
$darkred: #de2923;
$green: #43E97B;
$darkgreen: #000B05;
$yellow: #FBBF1D;
$dark-yellow: #c5c924;
$gray-100: rgba(0, 0, 0, .1);
$gray-200: rgba(0, 0, 0, .2);
$gray-300: rgba(0, 0, 0, .3);
$gray-400: rgba(0, 0, 0, .4);
$gray-500: rgba(0, 0, 0, .5);
$gray-600: rgba(0, 0, 0, .6);
$gray-700: rgba(0, 0, 0, .7);
$gray-800: rgba(0, 0, 0, .8);
$light-gray: #BBBBBB;
$dark-gray: #818D96;
$gray: #00000029;
$black: #273340;

$primary: $gray;
$secondary: $black;

a {
  transition: all ease .3s;
  outline: 0;

  &:focus {
    outline: 0;
  }
}

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1170px
) !default;

$grid-gutter-width: 30px !default;

$body-bg: #fff;
$body-color: $black;

$link-color: $green;
$link-hover-decoration: none;
$link-hover-color: black;


$btn-padding-x: 35px;
$btn-padding-y: 8px;
$btn-border-radius: 35px;
$btn-focus-width: 0;
$btn-font-weight: 800;
$btn-font-size: 1.1rem;

$input-btn-padding-y: .48rem;
$input-border-color: $gray-200;
$input-border-radius: 0;
$input-placeholder-color: $gray-300;
$input-focus-border-color: transparent;

$h1-font-size: 58px;
$h2-font-size: 58px;
$h3-font-size: 55px;
$h4-font-size: 24px;
$h5-font-size: 18px;
$h6-font-size: 15px;
$headings-margin-bottom: 20px;


$font-size-base: 1.15rem;
$line-height-base: 2;
$font-family-base: 'Gilroy';
$font-weight-base: 400;
$headings-font-family: 'Gilroy';
$headings-font-weight: 800;

$modal-lg: 800px !default;
$modal-md: 660px !default;
$modal-sm: 300px !default;

$custom-control-indicator-checked-bg: $yellow;
$custom-control-indicator-checked-color: $dark-gray;


$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
                (
                        0: 0,
                        1: ($spacer * .25),
                        2: ($spacer * .5),
                        3: $spacer,
                        4: ($spacer * 1.6),
                        5: ($spacer * 2.4),
                        6: ($spacer * 4),
                        7: ($spacer * 5)
                ),
                $spacers
);

$modal-content-border-radius: 10px !default;