.project-detail-page {
  background-color: #F9FAFC;
  .project-details {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 30px 50px;
    background-color: #fff;
    .details {
      display: flex;
      align-items: flex-start;
      .single-detail {
        margin-right: 40px;
        min-width: 200px;
        h4 {
          font-size: 18px;
          margin-bottom: 10px;
        }
        p {
          line-height: 1.2;
        }
        a {
          color: $black;
          &:not(:last-child) {
            &:after {
              content: ", ";
            }
          }
        }
      }
    }
    .btn-success {
      min-width: 180px;
    }
  }
  .content-wrapper {
    img {
      width: 100%;
      height: auto;
      box-shadow: 0px 26px 45px rgba(0, 0, 0, 0.1);
      border: none !important;
      &.aligncenter {
        display: block;
        margin: auto;
      }
    }
    .project-detail-content {
      margin: 30px 0;
    }
    .tags-list {
      text-align: center;
      a {
        background-color: rgba(39, 51, 64, .5);
        border-radius: 15px;
        padding: 5px 15px;
        color: #fff;
        margin: 0 5px;
        &:hover {
          background-color: $green;
        }
      }
    }
  }
  @media screen and (max-width: 667px) {
    .project-details {
      flex-direction: column;
      padding: 20px;
      margin-bottom: 10px;
      .details {
        flex-direction: column;
        display: none;
        .single-detail {
          width: 100%;
          margin-right: 0;
          text-align: center;
        }
      }
      .btn-success {
        width: 100%;
      }
    }
    .related-works {
      margin: 0 -10px;
    }
  }
}