.landing-page {
  .page-title-bar {
    min-height: 360px;
    .title-wrapper {
      padding-bottom: 30px;
      background-color: transparent;
      h1 {
        margin-bottom: 0;
      }
      p {
        border-bottom: none;
        padding-bottom: 0;
        margin-top: 20px;
      }
    }
  }
  .posts-list-wrapper {
    padding: 30px;
  }
  @media screen and (max-width: 667px) {
    .page-title-bar {
      min-height: 200px;
      .title-wrapper {
        padding-bottom: 20px;
        h1 {
          font-size: 36px;
        }
      }
    }
  }
}