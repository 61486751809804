.hero {
  position: relative;
  .main-slider {
    height: 100vh;
    .slide {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h1 {
        font-size: 72px;
        text-align: center;
        position: relative;
        z-index: 2;
        cursor: default;
        padding: 30px;
        span {
          font-size: 32px;
          font-weight: 300;
          display: block;
          text-align: center;
          margin-top: 20px;
          transition: all ease .5s;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -50px;
          visibility: hidden;
          opacity: 0;
          &.active {
            opacity: 1;
            visibility: visible;
            bottom: -30px;
          }
        }
      }
      .slide-backgrounds {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        height: 100vh;
        .background {
          height: 100vh;
          width: 100%;
          visibility: hidden;
          opacity: 0;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          transition: all ease 1.5s;
          overflow: hidden;
          &.active {
            visibility: visible;
            opacity: 1;
          }
          svg {
            height: 100vh;
            width: 100%;
            transition: all ease .5s;
            * {
              transition: all ease .5s;
            }
          }
          &:nth-of-type(1) {
            background: linear-gradient(90deg, #43E97B 2.48%, #38F9D7 97.56%);
          }
          &:nth-of-type(2) {
            background: linear-gradient(90deg, #FF7E5F 8.95%, #FEB47B 97.56%);
          }
          &:nth-of-type(3) {
            background: linear-gradient(90deg, #36D1DC 5.48%, #5B86E5 97.56%);
          }
        }
      }
    }
    &.hovered {
      .background {
        svg {
          transform: scale(1.05) rotate(5deg);
        }
      }
    }
  }
  .scroll-indicator {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    #arrow-down {
      -moz-animation: bounce 2s infinite;
      -webkit-animation: bounce 2s infinite;
      animation: bounce 2s infinite;
    }
  }
  @media screen and (max-width: 667px) {
    .main-slider {
      .slide {
        h1 {
          font-size: 52px;
          padding-top: 0;
          span {
            font-size: 26px;
          }
        }
      }
    }
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(10px);
  }
}