/*
Theme Name: Aykan
Theme URI: http://www.aykanburcak.com
Author: Aykan Burçak
Author URI: http://www.aykanburcak.com
Description:
Version: 1.0.0
License: GNU General Public License
License URI: https://www.gnu.org/licenses/gpl.html
Tags: aykan
Text Domain: aykan
*/

// Custom bootstrap variable overrides
@import "styles/bootstrap_variable_overrides";

// Plugins

// Bootstrap and its default variables
@import "node_modules/bootstrap/scss/bootstrap";

@import "styles/variables";
@import "styles/fonts";
@import "styles/mixins";
@import "styles/general";
@import "styles/layout";
@import "styles/module";
@import "styles/icons";


@import "styles/plugins/highlight";

// Elements
@import "styles/elements/header";
@import "styles/elements/footer";
@import "styles/elements/main-slider";
@import "styles/elements/skill-box";
@import "styles/elements/work-box";
@import "styles/elements/contact-form";
@import "styles/elements/page-title-bar";


@import "styles/pages/homepage";
@import "styles/pages/project-detail";
@import "styles/pages/post-detail";
@import "styles/pages/landing";
@import "styles/pages/404";
