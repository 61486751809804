.abicon {
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  &.linkedin {
    background-image: url("images/icons/linkedin.svg");
    width: 20px;
    height: 20px;
  }
  &.instagram {
    background-image: url("images/icons/instagram.svg");
    width: 20px;
    height: 20px;
  }
  &.twitter {
    background-image: url("images/icons/twitter.svg");
    width: 20px;
    height: 18px;
  }
  &.email {
    background-image: url("images/icons/email.svg");
    width: 20px;
    height: 16px;
  }
}