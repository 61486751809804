@font-face {
  font-family: "Gilroy";
  src: url("fonts/Gilroy/Gilroy-Regular/Gilroy-Regular.eot");
  src: url("fonts/Gilroy/Gilroy-Regular/Gilroy-Regular.eot?#iefix") format("embedded-opentype"),
  url("fonts/Gilroy/Gilroy-Regular/Gilroy-Regular.woff2") format("woff2"),
  url("fonts/Gilroy/Gilroy-Regular/Gilroy-Regular.woff") format("woff"),
  url("fonts/Gilroy/Gilroy-Regular/Gilroy-Regular.ttf") format("ttf"),
  url("fonts/Gilroy/Gilroy-Regular/Gilroy-Regular.svg#Gilroy-Regular") format("svg");
  font-style: normal;
  font-weight: normal;
}


@font-face {
  font-family: "Gilroy";
  src: url("fonts/Gilroy/Gilroy-Light/Gilroy-Light.eot");
  src: url("fonts/Gilroy/Gilroy-Light/Gilroy-Light.eot?#iefix") format("embedded-opentype"),
  url("fonts/Gilroy/Gilroy-Light/Gilroy-Light.woff2") format("woff2"),
  url("fonts/Gilroy/Gilroy-Light/Gilroy-Light.woff") format("woff"),
  url("fonts/Gilroy/Gilroy-Light/Gilroy-Light.ttf") format("ttf"),
  url("fonts/Gilroy/Gilroy-Light/Gilroy-Light.svg#Gilroy-Light") format("svg");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Gilroy";
  src: url("fonts/Gilroy/Gilroy-Bold/Gilroy-Bold.eot");
  src: url("fonts/Gilroy/Gilroy-Bold/Gilroy-Bold.eot?#iefix") format("embedded-opentype"),
  url("fonts/Gilroy/Gilroy-Bold/Gilroy-Bold.woff2") format("woff2"),
  url("fonts/Gilroy/Gilroy-Bold/Gilroy-Bold.woff") format("woff"),
  url("fonts/Gilroy/Gilroy-Bold/Gilroy-Bold.ttf") format("ttf"),
  url("fonts/Gilroy/Gilroy-Bold/Gilroy-Bold.svg#Gilroy-Bold") format("svg");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Gilroy";
  src: url("fonts/Gilroy/Gilroy-Medium/Gilroy-Medium.eot");
  src: url("fonts/Gilroy/Gilroy-Medium/Gilroy-Medium.eot?#iefix") format("embedded-opentype"),
  url("fonts/Gilroy/Gilroy-Medium/Gilroy-Medium.woff2") format("woff2"),
  url("fonts/Gilroy/Gilroy-Medium/Gilroy-Medium.woff") format("woff"),
  url("fonts/Gilroy/Gilroy-Medium/Gilroy-Medium.ttf") format("ttf"),
  url("fonts/Gilroy/Gilroy-Medium/Gilroy-Medium.svg#Gilroy-Medium") format("svg");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("fonts/Gilroy/Gilroy-SemiBold/Gilroy-SemiBold.eot");
  src: url("fonts/Gilroy/Gilroy-SemiBold/Gilroy-SemiBold.eot?#iefix") format("embedded-opentype"),
  url("fonts/Gilroy/Gilroy-SemiBold/Gilroy-SemiBold.woff2") format("woff2"),
  url("fonts/Gilroy/Gilroy-SemiBold/Gilroy-SemiBold.woff") format("woff"),
  url("fonts/Gilroy/Gilroy-SemiBold/Gilroy-SemiBold.ttf") format("ttf"),
  url("fonts/Gilroy/Gilroy-SemiBold/Gilroy-SemiBold.svg#Gilroy-SemiBold") format("svg");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Gilroy";
  src: url("fonts/Gilroy/Gilroy-ExtraBold/Gilroy-ExtraBold.eot");
  src: url("fonts/Gilroy/Gilroy-ExtraBold/Gilroy-ExtraBold.eot?#iefix") format("embedded-opentype"),
  url("fonts/Gilroy/Gilroy-ExtraBold/Gilroy-ExtraBold.woff2") format("woff2"),
  url("fonts/Gilroy/Gilroy-ExtraBold/Gilroy-ExtraBold.woff") format("woff"),
  url("fonts/Gilroy/Gilroy-ExtraBold/Gilroy-ExtraBold.ttf") format("ttf"),
  url("fonts/Gilroy/Gilroy-ExtraBold/Gilroy-ExtraBold.svg#Gilroy-ExtraBold") format("svg");
  font-style: normal;
  font-weight: 800;
}