.site-header {
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all ease .3s;
  padding: 50px;
  * {
    transition: all ease .3s;
  }
  .mobile-menu-opener {
    display: none;
    width: 28px;
    height: 28px;
    position: absolute;
    left: 20px;
    top: 50%;
    margin-top: -12px;
    span {
      width: 28px;
      border-bottom: 4px solid $black;
      display: block;
      position: absolute;
      &:nth-of-type(1) {
        top: 0;
      }
      &:nth-of-type(2) {
        top: 48%;
        width: 28px;
      }
      &:nth-of-type(3) {
        top: 98%;
        width: 16px;
      }
    }
  }
  .logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 35px;
  }
  ul.menu {
    @include list-unstyled;
    margin: 0;
    display: flex;
    align-items: center;
    li {
      margin-right: 30px;
      a {
        color: $black;
        font-weight: 700;
        font-size: 18px;
      }
      &.current-menu-item {
        a {
          text-decoration: underline;
        }
      }
    }
  }
  .language-switcher {
    @include list-unstyled;
    margin: 0;
    display: flex;
    align-items: center;
    a {
      color: $black;
      font-weight: 700;
      font-size: 18px;
      text-transform: uppercase;
    }
  }
  /*&.sticky {
    box-shadow: 0px 26px 45px rgba(0, 0, 0, 0.05);
    background-color: #fff;
  }*/
  @media screen and (max-width: 1024px) {
    padding: 45px 30px;
    ul.menu {
      li {
        a {
          font-size: 16px;
        }
      }
    }
    .language-switcher {
      a {
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .mobile-menu-opener {
      display: block;
      z-index: 99;
    }
    .language-switcher {
      position: absolute;
      right: 30px;
    }
    .menu-main-menu-container {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      left: 0;
      background-color: $black;
      top: 0;
      height: 100vh;
      padding: 100px 20px 30px 20px;
      width: 100%;
      transform: translateX(-30px);
      .menu {
        flex-direction: column;
        align-items: flex-start;
        position: initial;
        transform: none;
        li {
          margin-bottom: 15px;
          a {
            color: #fff;
            font-size: 32px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 667px) {
    .logo {
      img {
        height: 40px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  body.menu-active {
    &:before {
      content: "";
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(0,0,0,.7);
      z-index: 9;
    }
    .site-header {
      .mobile-menu-opener {
        span {
          &:nth-of-type(1) {
            transform: rotate(-45deg);
            top: 14px;
            border-color: #fff;
          }
          &:nth-of-type(2) {
            transform: rotate(45deg);
            border-color: #fff;
          }
          &:nth-of-type(3) {
            width: 0;
          }
        }
      }
      .menu-main-menu-container {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
}